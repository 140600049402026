import React, {useState, useEffect} from 'react';
import { Button, Input, InputNumber, Col, Row } from 'antd';
import './FilterLocation.scss';

interface FilterLocationProps {
    location: string;
    distance: string;
    onChange: (updates: { [key: string]: string }) => void;

}

export const FilterLocation: React.FC<FilterLocationProps> = ({ location, distance, onChange }) => {
    const [selectedLocation, setSelectedLocation] = useState<string>('');
    const [selectedDistance, setSelectedDistance] = useState<string>('');
    

    useEffect(() => {
        console.log('Location prop changed:', location)
        setSelectedLocation(location);
    }, [location]);

    useEffect(() => {
        console.log('Distance prop changed:', distance);
        setSelectedDistance(distance);
    }, [distance]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const { id, value: inputValue } = e.target;
      const reg = /^-?\d*(\.\d*)?$/;
      if (reg.test(inputValue) || inputValue === '' || inputValue === '-') {
        if(id === 'location'){
            setSelectedLocation(inputValue);
        } else {
            setSelectedDistance(inputValue);
        }
      }
    };

    const handleApply = () => {
        if(selectedLocation.length === 5 && selectedDistance){
            onChange({
                'location': selectedLocation,
                'distance': selectedDistance
            });
        }else{
            // show error message
        }
    }
    return (
    <Col >
        <Row className='filter-location-row'>
            <span> Within </span>
            <Input
          value={selectedDistance}
          onChange={handleChange}
          placeholder="Distance"
          maxLength={5}
          id='distance'
        /> 
            <span> miles of </span>
        </Row>
        <Row>
            <Input 
            value={selectedLocation}
            onChange={handleChange}
            placeholder="Enter Zip Code"
            maxLength={5}
            id='location'
            style={{ width: '140px', marginBottom: '4px' }}
            />
        </Row>
        <Row>
            <Button type="primary" 
                    onClick={() => handleApply()}
                    style={{ marginLeft: '100px'}}>Apply</Button>
        </Row>

    </Col>
    )
}

export default FilterLocation;
