declare global {
  interface Window {
    gtag: any;
  }
}

export const sendPageView = (url: any) => {
  // console.log('Sending page view', url)
  window.gtag('event', 'page_view', {
    page_path: url
  })
}
