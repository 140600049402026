import React, {useState} from 'react';
import './BidTimeWidget.scss';
import Countdown from '../../Countdown';
import { useNavigate } from 'react-router-dom';
import { StarFilled, StarOutlined } from '@ant-design/icons';
import { toggleFavorite } from '../../../utils/toggleFavorites';

interface BidTimeWidgetProps {
    car: any;
    isMobile: boolean;
}

const BidTimeWidget: React.FC<BidTimeWidgetProps> = ({ car, isMobile }) => {
    const calculateMargin = (currentBid: string, averagePrice: string) => {
        const bid = parseFloat(currentBid.replace(/[^0-9.-]+/g, ''));
        const avg = parseFloat(averagePrice.replace(/[^0-9.-]+/g, ''));
        const margin = avg - bid;
        return `${margin.toLocaleString()}`;
    };

    const navigate = useNavigate();
    const [isFavorited, setIsFavorited] = useState(car.Favorited);

    const handleToggleFavorite = (e: React.MouseEvent) => {
        e.stopPropagation();
        toggleFavorite({ link: car.link, favorited: !isFavorited });
        setIsFavorited(!isFavorited);
    };

    return (
        <div className={`car-info-widget ${isMobile ? 'mobile' : ''}`}>
            <div className="controls-row">
                <div className="back-button" onClick={() => navigate(-1)}>
                    ← Back
                </div>
                <div onClick={handleToggleFavorite}>
                    {isFavorited ? (
                        <StarFilled style={{ color: 'gold', cursor: 'pointer', fontSize: '2rem' }} />
                    
                    ) : (
                        <StarOutlined style={{ cursor: 'pointer', fontSize: '2rem' }} />
                    )}
                </div>
            </div>
            {isMobile ? (
            <>
            <h2 className="car-name">{car.name}</h2>
            <div className="mobile-info-row">
                <div className="info-item">
                    <span><Countdown unixTimestamp={car.timeLeft} horizontal /></span>
                </div>
            </div>
            <div className="mobile-info-row">
                <div className="info-item clickable" onClick={() => window.open(car.link, '_blank')}>
                    <span style={{ textDecoration: 'underline', color: 'blue' }}>
                        Visit {car.website}
                    </span>
                </div>
            </div>
        </>
        ) : (
            <>
            <div className="bid-info-row">
                <div
                    className="info-item clickable"
                    onClick={() => window.open(car.link, '_blank')}
                    style={{ cursor: 'pointer' }}
                >
                    <strong>Auction Website:</strong>
                    <span style={{ textDecoration: 'underline', color: 'blue' }}>
                        Visit {car.website}
                    </span>
                </div>
            </div>
            <div className="bid-info-row">
                <div className="info-item">
                    <strong>Time Left:</strong>
                    <span>
                        <Countdown unixTimestamp={car.timeLeft} />
                    </span>
                </div>
            </div>
            <div className="bid-info-row">
                <div className="info-item">
                    <div className="info-row">
                        <strong>Bids:</strong>
                        <span>{car.Bids}</span>
                    </div>
                    <div className="info-row">
                        <strong>Current Bid:</strong>
                        <span className="current-bid">{car.currentBid}</span>
                    </div>
                    <div className="info-row">
                        <strong>Average Retail:</strong>
                        <span>{car.AveragePrice}</span>
                    </div>
                    {calculateMargin(car.currentBid, car.AveragePrice) !== "NaN" && (
                        <>
                            <div className="info-row-divider"></div>
                            <div className="info-row">
                                <strong>Margin:</strong>
                                <span className={parseFloat(calculateMargin(car.currentBid, car.AveragePrice)) >= 0 ? 'positive' : 'negative'}>
                                    ${calculateMargin(car.currentBid, car.AveragePrice)}
                                </span>
                            </div>
                        </>
                    )}
                </div>
            </div>
            </>
        )}
        </div>
    );
};

export default BidTimeWidget;
