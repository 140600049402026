import React, {useState} from 'react';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import './SimilarCarsSection.scss';

interface SimilarCarsSectionProps {
    similarCars: Array<{
        Mileage: number;
        Price: number;
        Location: string;
        Name: string;
        Make: string;
        imageLink: string;
        Link: string;
        EndTime: number;
    }>;
    isMobile: boolean;
}

const SimilarCarsSection: React.FC<SimilarCarsSectionProps> = ({ similarCars, isMobile }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const formatMileage = (mileage: string | number): string => {
        let mileageStr = String(mileage).replace(/Miles|mi|,/gi, '').replace(/Shown/gi, '').trim();
        if (mileageStr.indexOf('k') > -1) {
            mileageStr = mileageStr.replace('k', '000');
        }
        const mileageNumber = Number(mileageStr);
        if (isNaN(mileageNumber)) {
            return String(mileage); // Return the original mileage value if conversion results in NaN
        }
        return `${mileageNumber.toLocaleString()} miles`;
    };
    
    const parseMileage = (mileage: string | number): number => {
        let mileageStr = String(mileage).replace(/Miles|mi|,/gi, '').replace(/Shown/gi, '').trim();
        if (mileageStr.indexOf('k') > -1) {
            mileageStr = mileageStr.replace('k', '000');
        }
        return parseInt(mileageStr, 10);
    };

    const calculateAverages = () => {
        const validCars = similarCars.filter(car => {
            const mileage = parseMileage(car.Mileage);
            return !isNaN(mileage);
        });
    
        const totalPrice = validCars.reduce((sum, car) => {
            const price = parseInt(car.Price.toString().replace(/,/g, ''));
            return sum + price;
        }, 0);
    
        const totalMileage = validCars.reduce((sum, car) => {
            const mileage = parseInt(car.Mileage.toString().replace(/,/g, '').replace('Miles', '').replace('miles', ''));
            return sum + mileage;
        }, 0);
        console.log("valid vars len", validCars.length);
        const avgPrice = Math.round(totalPrice / validCars.length);
        const avgMileage = Math.round(totalMileage / validCars.length);
        
        return {
            price: avgPrice.toLocaleString(),
            mileage: avgMileage.toLocaleString()
        };
    };

    const averages = calculateAverages();

    if (!similarCars || similarCars.length === 0) {
        return null;
    }
    
    return (
        
        <div className={`similar-cars-section ${isMobile ? 'mobile' : ''}`}>
            <div className="section-header" onClick={() => setIsExpanded(!isExpanded)}>
                <h2>Similar Sold Listings</h2>
                {isExpanded ? <DownOutlined /> : <UpOutlined />}
            </div>
            
            
            <div className="averages-container">
                <div className="average-stat">
                    <strong>Average Price of Sold Listings</strong>
                    <span>${averages.price}</span>
                </div>
                <div className="divider"></div>
                <div className="average-stat">
                    <strong>Average Mileage of Sold Listings</strong>
                    <span>{averages.mileage} miles</span>
                </div>
            </div>
            {isExpanded && (
                <>
            <div className={`similar-cars-grid ${isMobile ? 'mobile' : ''}`}>

                {similarCars.map((similarCar, index) => (
                    <div 
                        key={index} 
                        className="similar-car-card"
                        onClick={() => window.open(similarCar.Link, '_blank')}
                    >
                        <img 
                            src={similarCar.imageLink} 
                            alt={similarCar.Name}
                            className="similar-car-image"
                        />
                        <div className="similar-car-info">
                            <div className="name">{similarCar.Name}</div>
                            <div className="price">${similarCar.Price.toLocaleString()}</div>
                            <div className="mileage">{formatMileage(similarCar.Mileage)}</div>
                            <div className="location">
                                sold {new Date(similarCar.EndTime*1000).toLocaleDateString('en-US', { 
                                    month: 'short', 
                                    day: 'numeric', 
                                    year: 'numeric' 
                                })}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            </>
            )}
        </div>
            
    );
};

export default SimilarCarsSection;
