// utils/searchCars.ts
import axios from 'axios';
import { apiAxios, authApiAxiosWithRefresh } from './customAxios';

/**
 * Sends a POST request with the search term.
 * @param searchTerm The term to search for
 * @returns The response from the server
 */

interface car {
    link: string;
    favorited: boolean;
}

export const toggleFavorite = async (params: car) => {
    const { link, favorited } = params;

    const data = {
        link: link,
        favorited: favorited,
    }
    
    try {
        const response = await (
            await authApiAxiosWithRefresh()
          ).post('/toggleFavorite', data, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return response.data
    } catch (error) {
        console.error('Error posting data:', error);
    }
};
