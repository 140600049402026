import { StarOutlined, StarFilled } from '@ant-design/icons'; // Ant Design icons
import React, {useState} from 'react';
import { Table, Button } from 'antd';
import Countdown from '../Countdown';
import './CarTable.scss';
import logo from "../../assets/logo-white.png";
import { toggleFavorite } from '../../utils/toggleFavorites';
import { useNavigate } from 'react-router-dom';
// import { useHistory } from 'react-router-dom';
import {Car} from '../../types/home';


interface CarTableProps {
    page: number;
    itemsPerPage: number;
    dataSource: Array<Car>;
    carsLength: number;
    onPageChange: (page: number) => void;
    onSortChange: (sortField: string, sortOrder: string) => void; // Add sorting handler
    setDataSource: (cars: Array<Car>) => void;
    starToggle: string;
    setStarChanged: (starChanged: boolean) => void;
    onNavigateToDetails: () => void;
}

const CarTable: React.FC<CarTableProps> = ({ page, itemsPerPage, dataSource, carsLength, onPageChange, onSortChange, setDataSource, starToggle, setStarChanged, onNavigateToDetails}) => {
    const [currentPage, setCurrentPage] = useState(page);

    const handleTableChange = (pagination: any, filters: any, sorter: any) => {
        setCurrentPage(pagination.current);
        onPageChange(pagination.current); // Notify parent component of page change
        if (sorter.order) {
            onSortChange(sorter.field, sorter.order); // Notify parent component of sort change
        } else {
            onSortChange('', ''); // Reset sorting if no order
        }
    };

    const toggleStar = (name: string, index: number) => {
        const updatedDataSource = dataSource.map((car) => {
            if (car.name === name) {
                toggleFavorite({ link: car.link, favorited: !car.Favorited })
                return { ...car, Favorited: !car.Favorited }; // Toggle Favorited
            }
            return car;
        });
        if (starToggle == "starred") {
            setStarChanged(true);
        }
        setDataSource(updatedDataSource); // Update the dataSource state
    };
    

    const columns = [
        {
            title: 'Favorite',
            dataIndex: 'Favorited',
            key: 'Favorited',
            sorter: false,
            render: (text: boolean, record: any, index: number) => {
                return (
                    <span
                        onClick={(e) => {
                            e.stopPropagation();
                            toggleStar(record.name, index);
                        }}
                    >
                        {text ? (
                            <StarFilled style={{ color: 'gold', cursor: 'pointer', fontSize: '2rem' }} />
                        ) : (
                            <StarOutlined style={{ cursor: 'pointer', fontSize: '2rem' }} />
                        )}
                    </span>
                );
            },
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            sorter: true,
            render: (text: string, record: any) => (
                <div className="table-name-container">
                    <img 
                        src={record.image} 
                        alt={text} 
                        className="table-image" 
                        onError={(e) => { e.currentTarget.src = logo; }} // Optional fallback image
                    />
                    <span className="table-name">{text}</span>
                </div>
            ),
        },
        {
            title: () => (
                <div className="table-title-container">
                    <div className="table-title">Current Bid</div>
                    <div className="table-subtitle">Avg Retail</div>
                </div>
            ),
            dataIndex: 'currentBid',
            key: 'currentBid',
            sorter: true,
            render: (text: string, record: any) => {
                const currentBid = parseFloat(text.replace(/[^0-9.-]+/g, ""));
                const averagePrice = parseFloat(record.AveragePrice.replace(/[^0-9.-]+/g, ""));
                const reserve = record.reserveStatus === "No Reserve" ? "No Reserve" : null;
                let bidStyle = currentBid < averagePrice ? { color: 'green' } : { color: 'red' };
                if (isNaN(averagePrice)) {
                    bidStyle = { color: 'black' };
                }
                if (isNaN(currentBid)) {
                    bidStyle = { color: 'green' };
                }
                return (
                    <div className="table-bid-container">
                        {reserve ? <div className="table-reserve">{reserve}</div> : null}
                        <div className="table-bid" style={bidStyle}>{text}</div>
                        <div className="table-avg">{record.AveragePrice}</div>
                    </div>
                );
            },
        },
        {
            title: () => (
                <div>
                    <span className="table-title">Margin</span>
                    <span className="table-subtitle" > (<span style={{ fontStyle: 'italic' }}>Beta</span>)</span>
                </div>
            ),
            dataIndex: 'approxMargin',
            key: 'approxMargin',
            sorter: true,
            render: (text: string, record: any) => {
                const averagePrice = parseFloat(record.AveragePrice.replace(/[^0-9.-]+/g, ""));
                const currentBid = parseFloat(record.currentBid.replace(/[^0-9.-]+/g, ""));
                const difference = averagePrice - currentBid;
                let differenceStyle = currentBid < averagePrice ? { color: 'green' } : { color: 'red' };
                
                if (isNaN(currentBid) && isNaN(averagePrice)) {
                    return <span style={{ color: 'grey', fontSize: '14px', whiteSpace: 'nowrap' }}>Unavailable</span>;}
                else if (isNaN(currentBid)) {
                    differenceStyle = { color: 'green' };
                    return <span style={differenceStyle}>${averagePrice.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</span>;
                } else if (isNaN(averagePrice)) {
                    return <span style={{ color: 'grey', fontSize: '14px', whiteSpace: 'nowrap' }}>Unavailable</span>;}
                
               
        
                return (
                    <span style={differenceStyle}>
                        ${difference.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}
                    </span>
                );
           
            },
        },
        {
            title: 'Time Left',
            dataIndex: 'timeLeft',
            key: 'timeLeft',
            sorter: true,
            render: (timeLeft: string) => <Countdown unixTimestamp={timeLeft} />, // Use Countdown component
            width: 220,
        },
        {
            title: 'Location',
            dataIndex: 'location',
            key: 'location',
            sorter: true,
            render: (text: string) => <span className="table-location">{text}</span>,
        }, 
        {
            title: 'Website',
            dataIndex: 'website',
            key: 'website',
            sorter: true,
            render: (text: string) => <span className="table-website">{text}</span>,
        },
    ];

    const navigate = useNavigate();
    const onRowClick = (record: Car) => {
        navigate(`/car/${record.key}`); // Navigate to the car details page
    };
    



    return (
        <Table
            dataSource={dataSource}
            columns={columns}
            pagination={{ pageSize: itemsPerPage, current: page, total: carsLength, showSizeChanger: false  }}
            className="car-table"
            onChange={handleTableChange}
            // onRow={(record) => ({
            //     onClick: () => {
            //         window.open(record.link, '_blank');
            //     },
            //     style: { cursor: 'pointer' },
            // })}

            onRow={(record) => ({
                 onClick: () => {
                    onNavigateToDetails();
                    const encodedKey = encodeURIComponent(record.key); // Encode the key
                    navigate(`/car/${encodedKey}`, { state: { car: record } });
                },
                style: { cursor: 'pointer' }, 
            })}

        />
    );
};

export { CarTable };
