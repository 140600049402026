import React, { useState } from 'react';
import './ImageCarousel.scss';

interface ImageCarouselProps {
    images: string[];
    carName: string;
    isMobile: boolean;
}

const ImageCarousel: React.FC<ImageCarouselProps> = ({ images, carName, isMobile }) => {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [animationClass, setAnimationClass] = useState('');
    const [isAnimating, setIsAnimating] = useState(false);
    const [isFullscreen, setIsFullscreen] = useState(false);

    const handleNextImage = () => {
        if (isAnimating) return;
        setAnimationClass('slide-out-left');
        setIsAnimating(true);

        setTimeout(() => {
            setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
            setAnimationClass('slide-in-right');
        }, 150);
    };

    const handlePrevImage = () => {
        if (isAnimating) return;
        setAnimationClass('slide-out-right');
        setIsAnimating(true);

        setTimeout(() => {
            setCurrentImageIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
            setAnimationClass('slide-in-left');
        }, 150);

        setTimeout(() => {
            setAnimationClass('');
            setIsAnimating(false);
        }, 300);
    };

    const handleAnimationEnd = () => {
        setIsAnimating(false);
    };

    const toggleFullscreen = () => {
        if (!isMobile) {
            setIsFullscreen(!isFullscreen);
        }
    };

    return (
        <div
            className={`image-carousel ${isFullscreen ? 'fullscreen' : ''} ${isMobile ? 'mobile' : ''}`}
            onClick={toggleFullscreen}
        >
            <button
                className="arrow left-arrow"
                onClick={(e) => {
                    e.stopPropagation();
                    handlePrevImage();
                }}
            >
                &#8249;
            </button>
            {images.map((src, index) => (
                <img
                    key={index}
                    src={src}
                    alt={`${carName} - ${index + 1}`}
                    className={`car-image ${
                        index === currentImageIndex ? `active ${animationClass}` : ''
                    }`}
                    onAnimationEnd={handleAnimationEnd}
                />
            ))}
            <button
                className="arrow right-arrow"
                onClick={(e) => {
                    e.stopPropagation();
                    handleNextImage();
                }}
            >
                &#8250;
            </button>
        </div>
    );
};

export default ImageCarousel;
