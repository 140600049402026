import React, { useEffect, useState } from 'react';
import { Checkbox, Col, Row } from 'antd';

interface FilterReserveProps {
    reserve: string[];
    onChange: (id: string, value: string[]) => void;
}

const reserveStatuses = ['Reserve', 'No Reserve'];


export const FilterReserve: React.FC<FilterReserveProps> = ({ reserve, onChange }) => {
    const [checkedValues, setCheckedValues] = useState<string[]>(reserve || []);


    useEffect(() => {
        setCheckedValues(reserve || []);
    }, [reserve]);

    const handleChange = (checkedValues: string[]) => {
        setCheckedValues(checkedValues);
        onChange('reserveStatus', checkedValues);
    };

    return (
        <Checkbox.Group style={{ width: '100%' }} value={checkedValues} onChange={handleChange}>
            <Col>
                {reserveStatuses && reserveStatuses.length > 0 && reserveStatuses.map((status) => (
                    <Row key={status} style={{ marginBottom: '10px' }}>
                        <Checkbox value={status}>{status}</Checkbox>
                    </Row>
                ))}
            </Col>
        </Checkbox.Group>
    );
};

export default FilterReserve;