const formatMileage = (mileage: string | number): string => {
    let mileageStr = String(mileage).replace(/Miles|mi|,/gi, '').replace(/Shown/gi, '').trim();
    if (mileageStr.indexOf('k') > -1) {
        mileageStr = mileageStr.replace('k', '000');
    }
    const mileageNumber = Number(mileageStr);
    if (isNaN(mileageNumber)) {
        return String(mileage); // Return the original mileage value if conversion results in NaN
    }
    return `${mileageNumber.toLocaleString()} miles`;
};

export const formatCarData = (cars: any[]) => {
    return cars.map((car: any, index: number) => ({
        key: car.Link || index.toString(),
        name: car.Name,
        currentBid: (car.Price === -1 || car.Price === 0) ? "No Bids" : `$${car.Price.toLocaleString()}`,
        timeLeft: car.EndTime,
        LastUpdated: car.LastUpdated,
        location: car.Location,
        reserveStatus: car.reserveStatus,
        reserveMet: car?.reserveMet,
        website: car.Website,
        image: car.imageLink,
        link: car.Link,
        AveragePrice: car.AveragePrice == null || car.AveragePrice === -1
            ? "Unavailable"
            : `$${car.AveragePrice.toLocaleString()}`,
        Favorited: car.Favorited,
        AdditionalImages: car.additionalImageLinks,
        Bids: (car.Bids === -1 || car.Bids === 0) ? "No Bids" : `${car.Bids}`,
        Make: car.Make,
        Model: car.Model,
        Trim: car.Trim,
        Year: car.Year,
        Comments: `${car.Comments}`,
        Description: car.Description,
        Drivetrain: car.Drivetrain,
        Transmission: car.Transmission,
        Engine: car.Engine,
        VIN: car.VIN,
        Mileage: formatMileage(car.Mileage),
        bodyClass: car.bodyClass,
        exteriorColor: car.exteriorColor,
        interiorColor: car.interiorColor,
        sellerType: car.sellerType,
        titleStatus: car.titleStatus,
    }));
};
