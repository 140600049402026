// Countdown.tsx
import React, { useEffect, useState } from 'react';

interface CountdownProps {
  unixTimestamp: number | string;
  horizontal?: boolean;
}

const Countdown: React.FC<CountdownProps> = ({ unixTimestamp, horizontal }) => {
  // Convert unixTimestamp to a number before any calculations
  const targetTime = Number(unixTimestamp);
  const [timeLeft, setTimeLeft] = useState<number>(targetTime - Math.floor(Date.now() / 1000));

  useEffect(() => {
    const updateCountdown = () => {
      const now = Math.floor(Date.now() / 1000); // Current time in Unix
      const newTimeLeft = targetTime - now;
      setTimeLeft(newTimeLeft);
    };

    // Update the countdown every second
    const interval = setInterval(updateCountdown, 1000);

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [targetTime]);

  // Render countdown based on time left
  if (timeLeft > 500) {
    const days = Math.floor(timeLeft / 86400);
    const hours = Math.floor((timeLeft % 86400) / 3600);
    const minutes = Math.floor((timeLeft % 3600) / 60);
    const seconds = timeLeft % 60;

    const output1: string[] = [];
    const output2: string[] = [];
    if (days > 0) output1.push(`${days} day${days !== 1 ? "s" : ""}`);
    if (hours > 0) output1.push(`${hours} hour${hours !== 1 ? "s" : ""}`);
    if (minutes > 0) output2.push(`${minutes} minute${minutes !== 1 ? "s" : ""}`);
    if (seconds >= 0) output2.push(`${seconds} second${seconds !== 1 ? "s" : ""}`);

    return horizontal ? (
      <div style={{whiteSpace:"nowrap"}}>{[...output1, ...output2].join(", ")}</div>
    ) : (
      <>
        <div style={{whiteSpace:"nowrap"}}>{output1.join(", ")}</div>
        <div style={{whiteSpace:"nowrap"}}>{output2.join(", ")}</div>
      </>
    );
  } else if (timeLeft > -300) {
    return <span>Ending Soon</span>;
  } else {
    return <span>Time's up!</span>;
  }
};

export default Countdown;
