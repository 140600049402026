import React, { useEffect, useState, useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import './CarDetails.scss';
import TopBar from '../TopBar/TopBar';
import { getSimilarSold } from '../../utils/getSimilarSoldCars';
import { getSimilarLive } from '../../utils/getSimilarLiveCars';
import CarDetailsTable from './CarDetailsTable/CarDetailsTable';
import BidTimeWidget from './BidTimeWidget/BidTimeWidget';
import BidWidget from './BidWidget/BidWidget';
import ImageCarousel from './ImageCarousel/ImageCarousel';
import SimilarCarsSection from './SimilarCarsSection/SimilarCarsSection';
import SimilarLiveCars from './SimilarCarsSection/SimilarLiveCars';
import { formatCarData } from '../../utils/formatCarData';
import { Car } from '../../types/home';
import { getFirebaseAuth } from '../../utils/firebase';

interface SimilarSoldCar {
    Mileage: number;
    Price: number;
    Location: string;
    Name: string;
    Make: string;
    imageLink: string;
    Link: string;
    EndTime: number;
}

const CarDetail: React.FC = () => {
    const { id } = useParams<{ id?: string }>();
    const location = useLocation();
    const decodedId = id ? decodeURIComponent(id) : "Unknown Car ID";
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
                const handleResize = () => setIsMobile(window.innerWidth <= 768);
                window.addEventListener("resize", handleResize);
                return () => {
                    window.removeEventListener("resize", handleResize);
                };
            }, []);
    
    const [similarSoldCars, setSimilarSoldCars] = useState<SimilarSoldCar[]>([]);
    const [similarLiveCars, setSimilarLiveCars] = useState<Car[]>([]);

    const car = location.state?.car;

    const transformedCar = useMemo(() => {
        if (car?.website === 'eBay Motors') {
            return {
                ...car,
                image: car.image.replace('s-l225.jpg', 's-l1600.jpg'),
                AdditionalImages: car.AdditionalImages.map((img: string) => 
                    img.replace('s-l225.jpg', 's-l1600.jpg')
                )
            };
        }
        return car;
    }, [car]);

    const images = [transformedCar.image, ...transformedCar.AdditionalImages];

    //to remove
    //useEffect(() => {
    //    console.log("Car details:", car);
    //}, [car]);


    const fetchSimilarSold = async () => {
        const similarCars = await getSimilarSold({
            make: car.Make,
            model: car.Model,
            year: car.Year,
        });
        setSimilarSoldCars(similarCars || []);
    };

    const fetchSimilarLive = async () => {
        const similarCars = await getSimilarLive({
            make: car.Make,
            model: car.Model,
            year: car.Year,
            link: car.link,
        });
        const formattedCars = formatCarData(similarCars);

        setSimilarLiveCars(formattedCars || []);
    };

    useEffect(() => {
        //scroll to page top when component mounts
        window.scrollTo(0, 0);
        // Fetch similar sold cars and similar live cars when car data is available
        if (car) {
            const fetchSimilarCars = async () => {
                try {
                    // Wait for Firebase authentication to complete before fetching similar cars
                    const auth = await getFirebaseAuth();
                    await new Promise((resolve) => auth.onAuthStateChanged(resolve));
                    await Promise.all([fetchSimilarSold(), fetchSimilarLive()]);
                } catch (error) {
                    console.error("Error fetching similar cars:", error);
                }
            };
            fetchSimilarCars();
        }
    }, [car]);

    // const images = car?.images || [car?.image]; // Fallback for single image

    const [isFullscreen, setIsFullscreen] = useState(false);


    if (!car) {
        return (
            <div>
                <h1>Car details not available for ID: {decodedId}</h1>
            </div>
        );
    }


    return (
        <>
        {isMobile ? (
            <div className="mobile-layout">
                <TopBar title="Live Auctions" />
                <BidTimeWidget car={car} isMobile={isMobile} />
                <ImageCarousel images={images} carName={car.name} isMobile={isMobile} 
 />
                
                <BidWidget car={car} />
                
                <CarDetailsTable car={car} isMobile={isMobile}/>
                
                <SimilarCarsSection similarCars={similarSoldCars} isMobile={isMobile}/>

                <SimilarLiveCars similarCars={similarLiveCars} isMobile={isMobile}/>
            </div>
        ):(
        <div
            className={`car-details-container ${isFullscreen ? 'fullscreen-mode' : ''
                }`}
        >
            <TopBar title="Live Auctions" />

            <div className="bid-time-column">
                <BidTimeWidget car={car} isMobile={isMobile} />
            </div>

            <div className="main-content">
                <h1 className="car-title">{car.name}</h1>
                
                <ImageCarousel
                    images={images}
                    carName={car.name}
                    isMobile={isMobile} 
                />

                <CarDetailsTable car={car} isMobile={isMobile}/>
                
                <SimilarCarsSection similarCars={similarSoldCars} isMobile={isMobile}/>

                <SimilarLiveCars similarCars={similarLiveCars} isMobile={isMobile}/>

            </div>

        </div>
        )}
        </>
    );
};

export default CarDetail;
