// src/firebase.ts
import { initializeApp, getApps, getApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';


const firebaseConfig = {
  apiKey: "AIzaSyDn0OtKMSyC8jEgJo7aT5ffFJT25AdIgsY",
  authDomain: "kuaay-inc.firebaseapp.com",
  projectId: "kuaay-inc",
  storageBucket: "kuaay-inc.appspot.com",
  messagingSenderId: "1012168475022",
  appId: "1:1012168475022:web:7dbf52774e06519260e4df",
  measurementId: "G-L5TTRR06HY"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Export the authentication object
export const auth = getAuth(app);


export const getFirebaseAuth = () => getAuth(app);