import React, { useEffect, useState } from 'react';
import { Checkbox, Col, Row } from 'antd';

interface FilterReserveProps {
    sellerType: string[];
    onChange: (id: string, value: string[]) => void;
}

const sellerTypes = ['Dealer', 'Private'];


export const FilterSellerType: React.FC<FilterReserveProps> = ({ sellerType, onChange }) => {
    const [checkedValues, setCheckedValues] = useState<string[]>(sellerType || []);


    useEffect(() => {
        setCheckedValues(sellerType || []);
    }, [sellerType]);

    const handleChange = (checkedValues: string[]) => {
        setCheckedValues(checkedValues);
        onChange('sellerType', checkedValues);
    };

    return (
        <Checkbox.Group style={{ width: '100%' }} value={checkedValues} onChange={handleChange}>
            <Col>
                {sellerTypes && sellerTypes.length > 0 && sellerTypes.map((type) => (
                    <Row key={type} style={{ marginBottom: '10px' }}>
                        <Checkbox value={type}>{type}</Checkbox>
                    </Row>
                ))}
            </Col>
        </Checkbox.Group>
    );
};

export default FilterSellerType;