import React, { useEffect, useState } from 'react';
import { Checkbox, Col, Row } from 'antd';

interface FilterWebsiteProps {
    auction_sites: string[];
    websites: string[];
    onChange: (id: string, value: string[]) => void;
}



export const FilterWebsite: React.FC<FilterWebsiteProps> = ({ auction_sites, websites, onChange }) => {
    const [checkedValues, setCheckedValues] = useState<string[]>(websites || []);


    useEffect(() => {
        setCheckedValues(websites || []);
    }, [websites]);

    const handleChange = (checkedValues: string[]) => {
        setCheckedValues(checkedValues);
        onChange('websites', checkedValues);
    };

    return (
        <Checkbox.Group style={{ width: '100%' }} value={checkedValues} onChange={handleChange}>
            <Col>
                {auction_sites && auction_sites.length > 0 && auction_sites.map((site) => (
                    <Row key={site} style={{ marginBottom: '10px' }}>
                        <Checkbox value={site}>{site}</Checkbox>
                    </Row>
                ))}
            </Col>
        </Checkbox.Group>
    );
};

export default FilterWebsite;