import React, { useState, useEffect } from 'react';
import { Input } from "antd";
import './FilterYear.scss';

interface FilterYearProps {
  startYear: string;
  endYear: string;
  onChange: (id: string, value: string) => void;
}

export const FilterYear: React.FC<FilterYearProps> = ({ startYear, endYear, onChange }) => {
  const [localStartYear, setLocalStartYear] = useState(startYear);
  const [localEndYear, setLocalEndYear] = useState(endYear);

  useEffect(() => {
    setLocalStartYear(startYear);
  }, [startYear]);

  useEffect(() => {
    setLocalEndYear(endYear);
  }, [endYear]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value: inputValue } = e.target;
    const reg = /^-?\d*(\.\d*)?$/;
    if ((reg.test(inputValue) && inputValue.length <= 4)) {
      if (id === 'startYear') {
        setLocalStartYear(inputValue);
      } else if (id === 'endYear') {
        setLocalEndYear(inputValue);
      }
      if (inputValue.length === 4 || inputValue.length === 0) {
        onChange(id, inputValue);
      }
    }
  };

  return (
    <span className="filter-year-container">
      <div className="filter-year-input">
        <Input 
          value={localStartYear}
          onChange={handleChange}
          placeholder="Year"
          maxLength={4}
          id='startYear'
        />
      </div>
      <span>to</span>
      <div className="filter-year-input">
        <Input 
          value={localEndYear}
          onChange={handleChange}
          placeholder="Year"
          maxLength={4}
          id='endYear'
        />
      </div>
    </span>
  );
};