import { apiAxios, authApiAxiosWithRefresh } from './customAxios';
import axios from 'axios';

interface SimilarParams {
    make: string;
    model: string;
    year: string;
}

export const getSimilarSold = async (params: SimilarParams) => {
    const data = {
        make: params.make,
        model: params.model,
        year: params.year,
    }
    
    try {
        const response = await (
            await authApiAxiosWithRefresh()
        ).post('/getSimilarSold', data, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return response.data
    } catch (error) {
        console.error('Error posting data:', error);
    }
};
