import React from 'react';
import { Input, Checkbox } from 'antd';
import './SearchBar.scss';

interface SearchBarProps {
    onSearchTermChange: (term: string) => void;
}

const SearchBar: React.FC<SearchBarProps> = ({ onSearchTermChange }) => {
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onSearchTermChange(event.target.value);
    };

    return (
        <div className="search-bar">
            <Input
                placeholder="Search by name..."
                allowClear
                onChange={handleInputChange}
            />
            {/* Wyeth - remove buyers fee checkbox for now */}
            {/* <Checkbox className="checkbox">Include Buyers Fee in Price</Checkbox> */}
            
        </div>
    );
};

export { SearchBar };
