import React from 'react';
import './CarDetailsTable.scss';

interface CarDetailsTableProps {
    car: any;
    isMobile: boolean;
}

const CarDetailsTable: React.FC<CarDetailsTableProps> = ({ car, isMobile }) => {
    const vehicleDetails = [
        { label: 'Mileage', value: car.Mileage },
        { label: 'Make', value: car.Make },
        { label: 'Model', value: car.Model },
        { label: 'Trim', value: car.Trim },
        { label: 'Year', value: car.Year },
        { label: 'VIN', value: car.VIN },
        { label: 'Reserve Status', value: car.reserveStatus },
        { label: 'Reserve Met', value: car.reserveMet },
        { label: 'Engine', value: car.Engine },
        { label: 'Transmission', value: car.Transmission },
        { label: 'Drivetrain', value: car.Drivetrain },
        { label: 'Body Class', value: car.bodyClass },
        { label: 'Exterior Color', value: car.exteriorColor },
        { label: 'Interior Color', value: car.interiorColor },
        { label: 'Seller Type', value: car.sellerType },
        { label: 'Title Status', value: car.titleStatus },
        { label: 'Location', value: car.location }
    ];
    
    const filteredDetails = vehicleDetails.filter(detail => 
        detail.value && detail.value !== "-1"
    );
    
    const midpoint = Math.ceil(filteredDetails.length / 2);

    const renderValue = (label: string, value: string) => {
        if (label === 'Reserve Status' && value === 'No Reserve') {
            return <span className="table-reserve">{value}</span>;
        }
        return value;
    };

    return (
        <div className={`vehicle-details-table ${isMobile ? 'mobile' : ''}`}>
            {isMobile ? (
                <div className="table-column">
                    {filteredDetails.map((detail, index) => (
                        <div key={index} className="detail-row">
                            <strong>{detail.label}</strong>
                            <span>{renderValue(detail.label, detail.value)}</span>
                        </div>
                    ))}
                </div>
            ) : (
                <>
                    <div className="table-column">
                        {filteredDetails.slice(0, midpoint).map((detail, index) => (
                            <div key={index} className="detail-row">
                                <strong>{detail.label}</strong>
                                <span>{renderValue(detail.label, detail.value)}</span>
                            </div>
                        ))}
                    </div>
                    <div className="table-column">
                        {filteredDetails.slice(midpoint).map((detail, index) => (
                            <div key={index} className="detail-row">
                                <strong>{detail.label}</strong>
                                <span>{renderValue(detail.label, detail.value)}</span>
                            </div>
                        ))}
                    </div>
                </>
            )}
        </div>
    );
    
};

export default CarDetailsTable;
