import React from 'react';
import './BidWidget.scss';

interface BidWidgetProps {
    car: any;
}

const BidWidget: React.FC<BidWidgetProps> = ({ car }) => {
    const calculateMargin = (currentBid: string, averagePrice: string) => {
        const bid = parseFloat(currentBid.replace(/[^0-9.-]+/g, ''));
        const avg = parseFloat(averagePrice.replace(/[^0-9.-]+/g, ''));
        const margin = avg - bid;
        return `${margin.toLocaleString()}`;
    };

    return (
        <div className="bid-info-row">
            <div className="info-item">
                <div className="info-row">
                    <strong>Bids:</strong>
                    <span>{car.Bids}</span>
                </div>
                <div className="info-row">
                    <strong>Current Bid:</strong>
                    <span className="current-bid">{car.currentBid}</span>
                </div>
                <div className="info-row">
                    <strong>Average Retail:</strong>
                    <span>{car.AveragePrice}</span>
                </div>
                {calculateMargin(car.currentBid, car.AveragePrice) !== "NaN" && (
                    <>
                        <div className="info-row-divider"></div>
                        <div className="info-row">
                            <strong>Margin:</strong>
                            <span className={parseFloat(calculateMargin(car.currentBid, car.AveragePrice)) >= 0 ? 'positive' : 'negative'}>
                                ${calculateMargin(car.currentBid, car.AveragePrice)}
                            </span>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default BidWidget;
